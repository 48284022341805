import axios from "axios";

async function callGPT(query) {
  const apiUri = "https://njhy5n3ldl.execute-api.eu-west-1.amazonaws.com/dev/run";
  const apiKey = "tFiJF2LUDraHkCKbBmYfT5oWCZsH5Yk86FtjmTMU";

  const response = await axios.post(apiUri, query, {
    headers: {
      "x-api-key": apiKey,
      "Content-Type": "application/json",
    },
  });
  console.log(response);
  return response;
}

export default callGPT;
