import { AutoAwesome } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogTitle, Stack, TextField, styled, Backdrop } from "@mui/material";
import ConversationView from "./ConversationView";
import React from "react";

import callGPT from "../api/callGptAPI";

const initialState = {
  conversations: [
    {
      id: "intro",
      side: "left",
      messages: ["Hoi, Ik ben je Efteling vragen assistent", "Stel vragen zoals:", "Hoe is de sfeer binnen dit bedrijf?"],
    },
  ],
};

const ApplicationDetailBackDrop = styled(Backdrop)({
  backgroundColor: "rgba(255,255,255,0.38)",
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  marginTop: "2rem",
  marginLeft: "3%",
  marginRight: "3%",
  marginBottom: "3rem",
});

function AskDataHubDialog() {
  //  const [openDialog, setOpenDialog] = React.useState(true);
  //  const [isMutating, setIsMutating] = React.useState(true);
  //  const [closeDialog, setCloseDialog] = React.useState(false);

  const [prompt, setPrompt] = React.useState("Hoe is de sfeer binnen dit bedrijf?");
  const [conversations, setConversations] = React.useState(initialState.conversations);
  const [conversationCounter, setConversationCounter] = React.useState(0);

  async function sendPrompt() {
    // call endpoint
    console.log(prompt);

    //   setConversations(conversations.push({ id: 'response', side: 'right', messages: [res] }));
    setConversations((oldValue) => {
      console.log(oldValue);
      return [...oldValue, { id: `user-${conversationCounter}`, side: "right", messages: [prompt] }];
    });
    const res = await callGPT(prompt);
    console.log(res);
    setConversations((oldValue) => {
      console.log(oldValue);
      return [...oldValue, { id: `gptResponse-${conversationCounter}`, side: "left", messages: [res.data] }];
    });
    setConversationCounter(conversationCounter + 1);
    setPrompt("");
  }

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={true}
        hideBackdrop={true}
        PaperProps={{
          elevation: 0,
          sx: {
            // border: "solid 1px gray",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg height={0} width={0}>
              <linearGradient id="linearColors" x1="0%" x2="100%" y1="0%" y2="0">
                <stop offset="0%" stopColor="#b71234" />
                <stop offset="20%" stopColor="#b71234" />
                <stop offset="38%" stopColor="#b71234" />
                <stop offset="56%" stopColor="#be955b" />
                <stop offset="74%" stopColor="#be955b" />
                <stop offset="84%" stopColor="#be955b" />
                <stop offset="100%" stopColor="#be955b" />
              </linearGradient>

              {/* <linearGradient id="linearColors" x1="0%" x2="100%" y1="0%" y2="0">
                <stop offset="0%" stopColor="#8983ba" />
                <stop offset="20%" stopColor="#9e98cc" />
                <stop offset="38%" stopColor="#c29abe" />
                <stop offset="56%" stopColor="#e197b0" />
                <stop offset="74%" stopColor="#ff9a7d" />
                <stop offset="84%" stopColor="#ffb467" />
                <stop offset="100%" stopColor="#ffda8e" />
              </linearGradient> */}
            </svg>
            <AutoAwesome sx={{ fill: "url(#linearColors)", mr: 1 }} />
            <Box
              component={"span"}
              sx={{
                background: "linear-gradient(to right, #b71234 0%, #b71234 25%, #b71234 50%, #be955b 75%, #be955b 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Vraag Holle Bolle GPT
            </Box>
          </Box>
        </DialogTitle>
        <Stack
          alignItems="stretch"
          direction="column"
          justifyContent="space-between"
          spacing={2}
          sx={{ minWidth: "600px", maxWidth: "700px", minHeight: "600px" }}
        >
          <Box>
            <ConversationView conversations={conversations} isTyping={true} />
          </Box>
          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.grey[0],
              mt: 4,
              p: 2,
              borderTop: `1px solid ${theme.palette.action.disabled}`,
            })}
          >
            <Stack direction="column" spacing={2}>
              <Box
                sx={(theme) => ({
                  bgcolor: theme.palette.background.paper,
                })}
              >
                <TextField
                  fullWidth
                  multiline
                  onChange={(event) => {
                    console.log(event.target.value);
                    setPrompt(event.target.value);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      sendPrompt();
                      ev.preventDefault();
                    }
                  }}
                  placeholder="Hoe is de sfeer binnen dit bedrijf?"
                  rows={4}
                  value={prompt}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  //   color="primary"
                  style={{ backgroundColor: "#ac162c" }}
                  disabled={!prompt}
                  onClick={() => {
                    sendPrompt();
                  }}
                  variant="contained"
                >
                  Vraag Holle Bolle GPT
                </Button>
              </Box>
            </Stack>
          </Box>
        </Stack>

        {/* <DialogActions>
     <Button color="primary">Close</Button>
    </DialogActions> */}
      </Dialog>
    </>
  );
}

export default AskDataHubDialog;
